$bg-color1: white;
$bg-color2: #f4f4f4;
$font-color1: black;
$color-disabled: lightgray;

$color1: black;
$color2: rgb(214, 214, 214);

$accent-color: rgb(52, 100, 211);

$header-height: 60px;
$border-radius: 10px;

@mixin font($size) {
    font-family: 'Montserrat', sans-serif;
    font-size: $size;
}

$shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);

body {
    background-color: $bg-color2;

    font-family: 'Montserrat', sans-serif;
}

.header-wrapper {
    padding: 0 20px;
    height: $header-height;

    background-color: $bg-color1;
    color: $font-color1;

    box-shadow: $shadow;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 1200px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1280px) {
        width: calc(100vw - 80px);
        margin-left: 20px;
        margin-right: 45px;
    }
}

.content {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1280px) {
        width: calc(100vw - 80px);
        margin-left: 35px;
        margin-right: 45px;
    }
}

.welcome-form {
    display: block;

    width: 600px;
    height: auto;
    min-height: 300px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;

    padding: 50px 80px;
    box-sizing: border-box;

    box-shadow: $shadow;
    border-radius: $border-radius;

    background-color: $bg-color1;
    color: $font-color1;
}

.fluent-pad {
    display: inline-block;
    margin-top: 20px;
    font-size: 40px;
    height: 60px;
    color: $accent-color;
}

.userlist {
    width:100%;
    overflow: scroll;
    white-space: nowrap;
    margin-top: 10px;
    height: 30px;
    scrollbar-width: none;

    & li {
        display: inline-block;
        margin-right: 10px;
        @include font(14px);
    }
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.mq-editable-field {
    width: 100%;
    text-align: center;
    padding: 0.5rem 6px;
    font-size: 1.5rem;
    border: none;
    background-color: $bg-color1 !important;
    color: $font-color1;
}

.code-editor {
    width: 100%;
    min-height: 5rem;

    resize: none;
    padding: 1em 0.5em 0.5em 0.5em;

    box-shadow: $shadow;

    font-size: 12px;
    font-family: 'Fira Code', monospace;

    background-color: $bg-color1;
    color: $font-color1;

    border: none;
    outline: none;

    &:disabled {
        background-color: $color-disabled;
    }
}

.add-code-section-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    
    button {
        margin: 0.5rem;
        padding: 0.5rem;
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
        border-radius: 50%;
    }
}

.text-input {
    width: 100%;
    height: 40px;
    padding-left: 15px;
    padding-top: 10px;
    box-sizing: border-box;

    margin-top: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid $color2;

    font-style: normal;
    @include font(15px);
    color: $color1;

    &::placeholder {
        color: $color2;
    }

    &:hover,
    &:focus {
        outline: 1px solid white;
        border-bottom: 2px solid $accent-color;
    }
}

.form-caption {
    color: $accent-color;
    text-align: center;

    font-style: normal;
    font-weight: bold;
    @include font(20px);

    margin-bottom: 20px;
}

.graph-button {
    position: absolute;
    right: -0.5rem;
    top: 0.3rem;
    background-color: rgba(royalblue, 0.3);
    border: none;
    padding: 0.2rem 0.6rem;
    border-radius: 0.2rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(royalblue, 0.5);
    }
}

.graph-block {
    width: 100%;
    text-align: center;
    background-color: white;
    position: relative;
    border: 1px solid rgba(black, 0.3);
    margin-top: 0.5rem;
    padding: 0.4rem;

    .points-plotted {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 1;
    }
}

.join-button {
    background-color: white;
    color: $accent-color;
    border: 2px solid $accent-color;

    border-radius: $border-radius;

    font-style: normal;
    font-weight: bold;
    font-size: 15px;

    width: 100%;
    height: 46px;

    margin-top: 20px;

    outline: none;

    &:hover,
    &:focus {
        background-color: $accent-color;
        color: white;
        border: none;
    }
    &:disabled {
        color: $color-disabled;
        border-color: $color-disabled;
    }
}

.header-item {
    @include font(14px);
    text-transform: uppercase;

    & button {
        @include font(14px);
        text-transform: uppercase;

        background: none;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $accent-color;
        text-decoration: none;

        outline: none;

        &:hover,
        &:focus {
            color: $accent-color;
            border-bottom: 2px solid $accent-color;
        }
    }
}

.red {
    color: red;
}

.green {
    color: green;
}

.accent {
    color: $accent-color;
}

.bold {
    font-weight: bold;
}